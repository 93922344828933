<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Keywords'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Keywords Edit'"
      :title="'Keywords Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Keyword</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'keyword'"
              :label="'keyword'"
              :type="'text'"
              :placeholder="'Keyword'"
              :error-messages="validationErrors['keyword']"
              isBordered
            />
          </div>

          <p class="form-create__label">Parent Keyword</p>
          <div class="input-container">
            <div class="form-create__keyword">
              <input
                type="text"
                @focus="handleFocusParent"
                @blur="handleBlurParent"
                @input="debouncedGetParent"
                v-model="formDataToSend.parent_name"
                class="form-create__keyword-input"
              />

              <div v-if="parentList" class="form-create__keyword-list">
                <template v-if="parentArray.length > 0">
                  <div
                    @click="handleChooseItemParent(item)"
                    v-for="item in parentArray"
                    :key="item.id"
                    class="form-create__keyword-item"
                  >
                    {{ item.keyword.toLowerCase() }}
                  </div>
                </template>
                <div v-else class="form-create__keyword-item">NO keywords</div>
              </div>
            </div>

            <Notification :error-messages="validationErrors['keywordId']" />
          </div>

          <p class="form-create__label">Categories</p>
          <input
            v-model="categorySearch"
            class="form-create__search"
            type="text"
            placeholder="Search category..."
            @input="debouncedGetCategories"
          />

          <div class="input-container checkbox-container checkbox-container_margin">
            <div class="checkbox-container__item">
              <InputRadio
                :set-value="formDataToSend"
                :id="'category'"
                :values="categoriesList"
                :name="'fullName'"
                :value="'id'"
              />
            </div>
          </div>

          <Notification :error-messages="validationErrors['categoryId']" />

          <p class="form-create__label">Formats</p>
          <div class="input-container checkbox-container">
            <div v-for="item in templatesTypesList" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.type"
              />
            </div>
          </div>

          <p class="form-create__label">Sizes</p>
          <div class="input-container checkbox-container">
            <div v-for="item in templatesSizesList" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.size"
              />
            </div>
          </div>

          <p class="form-create__label">Key Points</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('key_points')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'key_points'"
              :label="'key_points'"
              :placeholder="''"
              :error-messages="validationErrors['keyPoints']"
              isBordered
            />
          </div>

          <p class="form-create__label">General Requirements</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('general_requirements')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'general_requirements'"
              :placeholder="''"
              :error-messages="validationErrors['generalRequirements']"
            />
          </div>

          <p class="form-create__label">Template Requirements</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('template_requirements')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'template_requirements'"
              :label="'template_requirements'"
              :placeholder="''"
              :error-messages="validationErrors['templateRequirements']"
              isBordered
            />
          </div>

          <p class="form-create__label">Preview Requirements</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('preview_requirements')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'preview_requirements'"
              :label="'preview_requirements'"
              :placeholder="''"
              :error-messages="validationErrors['preview_requirements']"
              isBordered
            />
          </div>

          <p class="form-create__label">Template Diagram</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('template_diagram')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'template_diagram'"
              :label="'template_diagram'"
              :placeholder="''"
              :error-messages="validationErrors['templateDiagram']"
              isBordered
            />
          </div>

          <p class="form-create__label">Information must have</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('information_must_have')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'information_must_have'"
              :label="'information_must_have'"
              :placeholder="''"
              :error-messages="validationErrors['informationMustHave']"
              isBordered
            />
          </div>

          <p class="form-create__label">Information can be added</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('information_can_be_added')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'information_can_be_added'"
              :label="'information_can_be_added'"
              :placeholder="''"
              :error-messages="validationErrors['informationCanBeAdded']"
              isBordered
            />
          </div>

          <p class="form-create__label">Examples</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('examples')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'examples'"
              :label="'examples'"
              :placeholder="''"
              :error-messages="validationErrors['examples']"
              isBordered
            />
          </div>

          <p class="form-create__label">Pages</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('pages')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'pages'"
              :label="'pages'"
              :type="'text'"
              :placeholder="'Pages'"
              :error-messages="validationErrors['pages']"
              isBordered
            />
          </div>

          <p class="form-create__label">Target audience</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('target_audience')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'target_audience'"
              :label="'target_audience'"
              :placeholder="''"
              :error-messages="validationErrors['target_audience']"
              isBordered
            />
          </div>

          <p class="form-create__label">Planned labor intensity in hours</p>
          <div
            v-if="
              keyword && keyword.attributesFromParent.includes('planned_labor_intensity_in_hours')
            "
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'planned_labor_intensity_in_hours'"
              :label="'planned_labor_intensity_in_hours'"
              :type="'text'"
              :placeholder="'Planned labor intensity in hours'"
              :error-messages="validationErrors['planned_labor_intensity_in_hours']"
              isBordered
            />
          </div>

          <p class="form-create__label">AI Requirements</p>
          <div
            v-if="keyword && keyword.attributesFromParent.includes('ai_requirements')"
            class="form-create__info"
          >
            Parent keyword requirements are output
          </div>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'ai_requirements'"
              :placeholder="''"
              :error-messages="validationErrors['aiRequirements']"
            />
          </div>
          <div class="form-create__btn">
            <MainButton @click.native="handleAiRequirements">Get AI Requirements</MainButton>
          </div>

          <!-- <p class="form-create__label">Requirements Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'requirements_link'"
              :label="'requirements_link'"
              :type="'text'"
              :placeholder="'Requirements Link'"
              :error-messages="validationErrors['requirementsLink']"
              isBordered
            />
          </div> -->

          <div v-if="keyword" class="form-create__tree">
            <div class="form-create__tree-title">Keyword Hierarchy</div>
            <div class="form-create__tree-item first">
              <router-link :to="handleToCategory(keyword.category[0].id)">
                {{ keyword.category[0].fullName }}
              </router-link>
            </div>

            <div v-if="keyword.parentId" class="form-create__tree-item second">
              <router-link :to="handleToKeyword(keyword.parent.id)">
                {{ keyword.parent.keyword }}
              </router-link>
            </div>

            <div v-else class="form-create__tree-item second active">
              {{ keyword.keyword }}
            </div>
            <div v-if="keyword.children[0] && !keyword.parentId" class="checkbox-container">
              <div
                v-for="item in keyword.children[0]"
                :key="item.id"
                :class="['form-create__tree-item third', keyword.id == item.id && active]"
              >
                <router-link :to="handleToKeyword(item.id)">
                  {{ item.keyword }}
                </router-link>
              </div>
            </div>
            <div v-if="keyword.parentId" class="checkbox-container">
              <div :class="['form-create__tree-item third active']">
                {{ keyword.keyword }}
              </div>
            </div>
          </div>

          <div v-if="keyword && keyword.templates[0]" class="form-create__templates">
            <div class="form-create__templates-title">Linked Templates</div>
            <div class="checkbox-container">
              <div
                v-for="item in keyword.templates[0]"
                :key="item.id"
                class="form-create__templates-item"
              >
                <router-link :to="handleToTemplate(item.id)">
                  {{ item.name }}
                </router-link>
              </div>
            </div>
          </div>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>

        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import templatesApi from "~/api/templates";
import categoriesApi from "~/api/categories";
import referencesApi from "~/api/references";
import templateApi from "~/api/templates";
import { debounce } from "lodash";
import keysToSnakeCase from "snakecase-keys";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import Notification from "~/components/molecules/Notification.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";

export default {
  name: "KeywordEdit",
  metaInfo: {
    title: "Keyword Edit",
  },
  data() {
    return {
      parentList: false,
      formDataToSend: {
        keyword: "",
        category: null,
        // requirements_link: "",
        size: {},
        type: {},
        information_must_have: "",
        information_can_be_added: "",
        examples: "",
        pages: "",
        target_audience: "",
        preview_requirements: "",
        planned_labor_intensity_in_hours: "",
        parent_name: "",
        parent_id: null,
        ai_requirements: "",
        key_points: "",
        template_requirements: "",
        template_diagram: "",
        general_requirements: "",
      },
      parentArray: [],
      keyword: null,
      categoriesList: [],
      categorySearch: "",
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputRadio,
    Notification,
    MainCheckbox,
    InputTextarea,
    TextEditor,
  },
  mounted() {
    this.getItem();
    this.getCategories();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("references", ["templatesSizesList", "templatesTypesList"]),
    getFirstLink() {
      return ROUTE.KEYWORDS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getData() {
      await referencesApi
        .getTemplatesSizesAll()
        .then((res) => {
          this.$store.commit("references/setTemplatesSizesList", res.data.items);
        })
        .catch((error) => {})
        .finally(() => {});

      await referencesApi
        .getTemplatesTypes()
        .then((res) => {
          this.$store.commit("references/setTemplatesTypesList", res.data[0].data);
        })
        .catch((error) => {})
        .finally(() => {});

      await this.setSize();
      await this.setType();
    },
    setSize() {
      const size = this.templatesSizesList;
      for (let i = 0; size.length > i; i++) {
        if (this.formDataToSend.size[size[i].id] == undefined) {
          this.$set(this.formDataToSend.size, size[i].id, false);
        }
      }
    },
    setType() {
      const type = this.templatesTypesList;
      console.log(this.templatesTypesList);
      for (let i = 0; type.length > i; i++) {
        if (this.formDataToSend.type[type[i].id] == undefined) {
          this.$set(this.formDataToSend.type, type[i].id, false);
        }
      }
    },
    handleSubmit() {
      const data = {
        keyword: this.formDataToSend.keyword.trim(),
        category_id: this.formDataToSend.category,
        // requirements_link: this.formDataToSend.requirements_link.trim(),
        key_points: this.formDataToSend.key_points.trim(),
        information_must_have: this.formDataToSend.information_must_have.trim(),
        information_can_be_added: this.formDataToSend.information_can_be_added.trim(),
        pages: this.formDataToSend.pages.trim(),
        target_audience: this.formDataToSend.target_audience.trim(),
        preview_requirements: this.formDataToSend.preview_requirements.trim(),
        planned_labor_intensity_in_hours:
          this.formDataToSend.planned_labor_intensity_in_hours.trim(),
        parent_id: this.formDataToSend.parent_id,
        ai_requirements: this.formDataToSend.ai_requirements,

        template_requirements: this.formDataToSend.template_requirements,
        template_diagram: this.formDataToSend.template_diagram,
        general_requirements: this.formDataToSend.general_requirements,
      };

      const examples = this.formDataToSend.examples.toLowerCase().split(/\r\n|\r|\n/g);
      const examplesSend = examples.filter((str) => str.trim() !== "");

      data.examples = examplesSend;

      const size = Object.keys(this.formDataToSend.size);
      if (size.length) {
        let sizeSend = [];
        for (let i = 0; size.length > i; i++) {
          if (this.formDataToSend.size[size[i]] == true) {
            sizeSend.push(size[i]);
          }
        }
        if (sizeSend.length > 0) {
          data.sizes = sizeSend;
        }
      }

      const type = Object.keys(this.formDataToSend.type);
      if (type.length) {
        let typeSend = [];
        for (let i = 0; type.length > i; i++) {
          if (this.formDataToSend.type[type[i]] == true) {
            typeSend.push(type[i]);
          }
        }
        if (typeSend.length > 0) {
          data.types = typeSend;
        }
      }

      this.$store.commit("templates/setLoading", true);
      const url = `/${this.getId}`;
      try {
        templatesApi
          .editKeywords(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("templates/setError", {});
              this.$store.commit("templates/setValidationErrors", {});
              this.handleBackToList();
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("templates/setError", data);
            if (data.errors) {
              this.$store.commit("templates/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
      });
    },
    handleToCategory(id) {
      return ROUTE.CATEGORY_EDIT.replace(":id", id);
    },
    handleToKeyword(id) {
      return ROUTE.KEYWORDS_EDIT.replace(":id", id);
    },
    handleToTemplate(id) {
      return ROUTE.TEMPLATE_EDIT.replace(":id", id);
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        templatesApi.getKeywordsItem(url).then((res) => {
          this.$store.commit("templates/setError", {});
          this.$store.commit("templates/setValidationErrors", {});
          const data = res.data;
          this.keyword = res.data;
          this.formDataToSend.keyword = data.keyword;
          this.formDataToSend.category = data.categoryId;
          // this.formDataToSend.requirements_link = data.requirementsLink || "";
          this.formDataToSend.information_must_have = data.informationMustHave || "";
          this.formDataToSend.key_points = data.keyPoints || "";
          this.formDataToSend.information_can_be_added = data.informationCanBeAdded || "";
          this.formDataToSend.pages = data.pages || "";
          this.formDataToSend.target_audience = data.targetAudience || "";
          this.formDataToSend.preview_requirements = data.previewRequirements || "";
          this.formDataToSend.planned_labor_intensity_in_hours =
            data.plannedLaborIntensityInHours || "";
          this.formDataToSend.ai_requirements = data.aiRequirements || "";

          this.formDataToSend.template_requirements = data.templateRequirements || "";
          this.formDataToSend.template_diagram = data.templateDiagram || "";
          this.formDataToSend.general_requirements = data.generalRequirements || "";

          if (data.parent) {
            this.formDataToSend.parent_name = data.parent.keyword;
            this.formDataToSend.parent_id = data.parent.id;
          }

          if (data.examples) {
            this.formDataToSend.examples = data.examples.join("\n");
          }

          const type = data.allowedTypes?.[0];
          if (type != undefined && type.length > 0) {
            for (let i = 0; type.length > i; i++) {
              this.$set(this.formDataToSend.type, type[i].id, true);
            }
          }

          const size = data.allowedSizes?.[0];
          if (size != undefined && size.length > 0) {
            for (let i = 0; size.length > i; i++) {
              this.$set(this.formDataToSend.size, size[i].id, true);
            }
          }

          this.getData();
        });
      } catch (error) {
        console.log(error);
      }
    },
    getCategories() {
      if (this.categorySearch.trim().length > 2) {
        const data = {
          search: this.categorySearch.trim(),
        };
        try {
          categoriesApi.getCategoriesListFilter("", data).then((res) => {
            const data = res.data.items.data;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          categoriesApi.getCategoriesActive().then((res) => {
            const data = res.data.items;
            this.categoriesList = data;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    debouncedGetCategories: debounce(function () {
      this.getCategories();
    }, 300),

    getParent() {
      const data = {};
      data.search = this.formDataToSend.parent_name.trim();
      templateApi
        .getKeywordsListFilter(``, data)
        .then((res) => {
          const array = res.data.data.items.data;
          const setArray = [];
          for (let i = 0; array.length > i; i++) {
            if (!array[i].parentId) {
              setArray.push(array[i]);
            }
          }
          this.parentArray = setArray;

          this.parentArray.sort((a, b) => {
            if (a.keyword < b.keyword) {
              return -1;
            }
            if (a.keyword > b.keyword) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    debouncedGetParent: debounce(function () {
      this.getParent();
    }, 300),

    handleFocusParent() {
      this.parentList = true;
      this.debouncedGetParent();
    },

    handleBlurParent() {
      setTimeout(() => {
        this.parentList = false;
        this.parentArray = [];
      }, 500);
    },

    handleChooseItemParent(item) {
      this.formDataToSend.parent_id = item.id;
      this.formDataToSend.parent_name = item.keyword;
    },

    handleAiRequirements() {
      if (this.formDataToSend.keyword.length > 2) {
        this.$store.commit("templates/setLoading", true);
        const url = `?keyword=${this.formDataToSend.keyword}`;
        try {
          templateApi.getAiRequirements(url).then((res) => {
            this.$store.commit("templates/setLoading", false);
            console.log(res);
            if (res.data.success) {
              this.formDataToSend.ai_requirements = res.data.data.response;
            }
          });
        } catch (error) {
          console.log(error);
          this.$store.commit("templates/setLoading", false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
.form-create {
  &__search {
    font-size: 18px;
    margin-top: 20px;
    width: 400px;
    padding: 5px 10px;
  }

  &__info {
    padding: 10px 0;
    font-size: 20px;
    font-weight: 700;
    color: rgb(202, 22, 22);
  }

  &__keyword-input {
    width: 100%;
    font-size: 18px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  &__keyword {
    position: relative;
    margin-bottom: 10px;
  }

  &__keyword-list {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    border: 1px solid gray;
    background: #fff;
    padding: 10px 0 10px;
    z-index: 10;
  }

  &__keyword-item {
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background: rgb(216, 213, 213);
    }
  }

  &__tree {
    margin-bottom: 30px;
    background: rgb(243, 243, 243);
    padding: 15px;
    border-radius: 10px;
  }

  &__tree-title {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__tree-item {
    padding-bottom: 10px;

    &.first {
      font-size: 22px;
    }

    &.second {
      font-size: 20px;
      padding-left: 30px;

      &.active {
        font-weight: 700;
      }
    }

    &.third {
      font-size: 18px;
      padding-left: 60px;

      &.active {
        font-weight: 700;
      }
    }
  }

  &__templates {
    margin-bottom: 30px;
    background: rgb(243, 243, 243);
    padding: 15px;
    border-radius: 10px;
  }

  &__templates-title {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__templates-item {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
</style>
